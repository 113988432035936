import moment from "moment";
import jwt from 'jwt-decode'
import AuthService from "../services/auth.service";
import history from './customHistory';

export const _setToken = (data) => {
  localStorage.setItem('accessToken', data.access);
  localStorage.setItem('refreshToken', data.refresh);
  const token = jwt(data.access);
  localStorage.setItem('expiryToken', token.exp);
}

let refrestTimer;
export const logout = async () => {
  if (refrestTimer) {
    clearInterval(refrestTimer)
    refrestTimer = null;
  }
  await AuthService.logOutUser().then(()=>{
    localStorage.clear();
    history.push('/signin');
    window.location.reload();
  })
}

export const isUserAuthenticated = () => {
  if (localStorage.getItem('accessToken')) {
    const token = jwt(localStorage.getItem('accessToken'));
    const refreshToken = jwt(localStorage.getItem('refreshToken'));
    return token?.token_type === 'access' && !(new Date(refreshToken.exp * 1000) < new Date())
  }
  return false
}

export const setupRefreshTimer = async () => {
  if (localStorage.getItem('accessToken')) {
    const expiryToken = localStorage.getItem('expiryToken')
    const duration = moment(new Date(expiryToken * 1000)).diff(new Date(), 'seconds')
    refrestTimer = setInterval(async () => {
      await callRefresh()
    }, (duration - 100) * 1000)
  } else {
    logout()
  }
}

export const callRefresh = async _ => {
  const refreshData = await AuthService.callRefresh().then((response) => {
    if (response.status === 200 || response.status === 201) {
      _setToken(response.data)
    } else {
      logout()
    }
  }).catch((err) => {
    logout()
  });
  return refreshData
}