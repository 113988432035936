import { logEvent } from "firebase/analytics";
import { analytics } from './firebase';

export const trackPageView = () => { // Track Home page view
  logEvent(analytics, 'page_view');
};

export const trackLogin = () => {
  logEvent(analytics, 'login');
};

export const trackCreateBot = () => {
  logEvent(analytics, 'create_bot');
};

export const trackSendButtonClick = () => {
  logEvent(analytics, 'send_button_click');
};

export const trackSettingSave = () => {
  logEvent(analytics, 'setting_save');
};

export const trackBotSendButtonClick = (botName) => {
  logEvent(analytics, 'bot_send_button_click', {
    bot_name: botName,
  });
};