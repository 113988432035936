import React, { useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";

import { isUserAuthenticated, _setToken, setupRefreshTimer } from "../../utils/authFunctions";

import NavBar from "./nav";
import MainBody from "./main";
import Footer from "./footer";

import { trackPageView } from "../../utils/eventAnalytics";

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  
  useEffect(() => {
    if (!location.pathname.startsWith('/demo') && !location.pathname.startsWith('/terms') && !location.pathname.startsWith('/privacy') && location.pathname !== '/') {
      if (!isUserAuthenticated()) {
        if (searchParams.get('code') && window.location.search) {
        } else {
          navigate('/signin')
        }
      }
    }

    if (location.pathname === '/') { // Track Homepage view
      trackPageView();
    }
  }, [location])

  return (
    <div>
      <NavBar />
      <MainBody />
      {!location.pathname.startsWith('/signin') ? <Footer /> : null}
    </div>
  )
}

export default Layout;