import {lazy} from 'react';

import Layout from '../molecules/layout';
import Loadable from '../elements/Loadable';
import { Navigate } from 'react-router-dom';

const MyChatBotsPage = Loadable(lazy(() => import('../pages/MyChatBot')));
const ChatBotPage = Loadable(lazy(() => import('../pages/ChatBot/CsvBot')));
const DocChatBotPage = Loadable(lazy(() => import('../pages/ChatBot/DocBot')));
const ChatBotIframePage = Loadable(lazy(() => import('../pages/ChatBot/CsvBot/iframeBot')));
const ChatBotDocIframePage = Loadable(lazy(() => import('../pages/ChatBot/DocBot/iframeBot')));
const ChatBotHomeIframePage = Loadable(lazy(() => import('../pages/ChatBot/CsvBot/homePageIframeBot')));
const ChatBotDemoPage = Loadable(lazy(() => import('../pages/MyChatBot/demo/demoTabs')));
const DemoChatPage = Loadable(lazy(() => import('../pages/ChatBot/CsvBot/demo')));
const DemoDocChatPage = Loadable(lazy(() => import('../pages/ChatBot/DocBot/demo')));
const ChatBotSettingsPage = Loadable(lazy(() => import('../pages/ChatBot/CsvBot/settings')));
const DocChatBotSettingsPage = Loadable(lazy(() => import('../pages/ChatBot/DocBot/settings')));
const CreateChatBotPage = Loadable(lazy(() => import('../pages/ChatBot/create')));
const LoginPage = Loadable(lazy(() => import('../pages/Login')));
const HomePage = Loadable(lazy(() => import('../pages/HomePage')));
const PrivacyPage = Loadable(lazy(() => import('../pages/Privacy')));
const TermsPage = Loadable(lazy(() => import('../pages/Terms')));

const MainRoutes = [
  {
    path: 'bot-iframe/:chatbotId',
    element: <ChatBotIframePage />
  },
  {
    path: 'doc-bot-iframe/:chatbotId',
    element: <ChatBotDocIframePage />
  },
  {
    path: 'home-bot-iframe/:chatbotId',
    element: <ChatBotHomeIframePage />
  },
  {
    path: 'signin',
    element: <LoginPage />
  },
  {
    path: '/',
    element: <Layout/>,
    children: [
      {
        path: '',
        element: <HomePage />
      },
      
      {
        path: 'demo',
        element: <ChatBotDemoPage />
      },
      {
        path: 'demo/csv/:chatbotId',
        element: <DemoChatPage />
      },
      {
        path: 'demo/doc/:chatbotId',
        element: <DemoDocChatPage />
      },
      {
        path: 'my-chatbots',
        element: <MyChatBotsPage />
      },
      {
        path: 'create-new-chatbot',
        element: <CreateChatBotPage />
      },
      {
        path: 'chatbot/csv/:chatbotId',
        element: <ChatBotPage />
      },
      {
        path: 'chatbot/doc/:chatbotId',
        element: <DocChatBotPage />
      },
      {
        path: 'chatbot/csv/:chatbotId/settings',
        element: <ChatBotSettingsPage />
      },
      {
        path: 'chatbot/doc/:chatbotId/settings',
        element: <DocChatBotSettingsPage />
      },
      {
        path: 'terms',
        element: <TermsPage />
      },
      {
        path: 'privacy',
        element: <PrivacyPage />
      },
    ]
  }
];

export default MainRoutes;