import React from "react";
import { Link } from "react-router-dom";
import AssistifyWhiteLogo from "../../assets/images/assistify-white-letter.png"

import styled from "styled-components";


const NavMenuItem = styled(Link)`
  color: #848484;
  font-size: 18px;
`

const ContactSpan = styled('span')`
  color: #848484;
  font-size: 18px;
`

const EmailSpan = styled('span')`
  font-size: 18px;
`

const Footer = () => {
  return (
    <footer className="mx-auto max-w-[1920px]">
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-8 mb-0 px-8 text-black transition-colors duration-150 bg-footer-common">
        <div className="col-span-1 lg:col-span-3 mt-16 mb-8">
          <Link className="flex flex-initial items-center font-bold md:mr-24" to="/">
            <span className="mb-6">
              <img style={{height: 38}} src={AssistifyWhiteLogo}/>
            </span>
          </Link>
          <NavMenuItem className="block" to="/privacy">Privacy Policy</NavMenuItem>
          <NavMenuItem className="block leading=4" to="/terms">Terms of Service</NavMenuItem>
        </div>
        <div className="col-span-3 lg:col-span-2"></div>
        <div className="col-span-1 lg:col-span-2"></div>
        <div className="col-span-1 lg:col-span-5 flex items-start lg:justify-end text-black mt-16">
          <div className="flex space-x-6 items-center h-10">
            <ContactSpan>Contact:</ContactSpan>
            <EmailSpan><a href="mailto:irfaan.aa@gmail.com"> irfaan.aa@gmail.com</a></EmailSpan>
          </div>
        </div>
      </div>
      {/* <div className="bg-common">
        <p className="py-2 text-center">
          <ContactSpan>Contact:</ContactSpan><br />
          <EmailSpan><a href="mailto:irfaan.aa@gmail.com"> irfaan.aa@gmail.com</a></EmailSpan>
        </p>
      </div> */}
    </footer>
  )
}

export default Footer;