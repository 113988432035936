import React from "react";
import { Outlet } from 'react-router-dom';

const MainBody = () => {
  return (
    <main>
      <section className="bg-common">
        <div className="max-w-6xl mx-auto py-8 sm:py-24 px-4 sm:px-6 lg:px-8">
          <Outlet />
        </div>
      </section>
    </main>
  )
}

export default MainBody;