import { requestWithToken, simpleRequest } from "../apiConfigs/request";

export const loginUser = (userData) => {
  return simpleRequest({
    url: '/auth/login/',
    method: 'POST',
    data: userData
  });
}

export const logOutUser = ()=> {
  return requestWithToken({
    url: '/auth/logout/',
    method: "POST",
    data: {
      'refresh_token': localStorage.getItem("refreshToken")
    }
  })
}

export const callRefresh = () => {
  return simpleRequest({
    url: '/auth/token/refresh/',
    method: "POST",
    data: {
      'refresh': localStorage.getItem("refreshToken")
    }
  })
}

export const googleLoginUser = (query) => {
  return simpleRequest({
    url: `/auth/login/google/${query}`,
    method: 'GET',
  });
}

const AuthService = {
  callRefresh,
  logOutUser,
  loginUser,
}
export default AuthService;