import axios from "axios";
import AuthService from "../services/auth.service";
import { setupRefreshTimer, callRefresh } from '../utils/authFunctions';
import history from '../utils/customHistory';

export const requestWithToken = (options) => {

    const header = {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${localStorage.getItem("accessToken")}`
    }

    const instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: header
    })

    const logout = () => {
        
    }

    const onSuccess = (response) => {
      return response;
    }

    const onError = async (error) => {
      if (error.request) {
        return Promise.reject(error.request);
      } else if (error?.response || error?.statusCode) {
          if (error?.response?.status === 405 || error?.statusCode === 405) {
            return Promise.reject(error.response || error.message);
          }
          if (error?.response?.status === 403 || error?.statusCode === 403) {
            if (options.url.includes('refresh-tokens')) {
              logout()
            }
          } else if (error?.response?.status === 401 || error?.statusCode === 401) {
            //If refresh token expires
            if (options.url.includes('refresh-tokens') || window.location.pathname.includes('login')) {
              logout()
            } else {
              //If other api's fails
              await callRefresh()
              setupRefreshTimer();
              requestWithToken(options);
            }
          } else if (error?.response?.status === 404 || error?.statusCode === 404) {
            history.push('/404')
          }
          return Promise.reject(!!error.response.data ? error?.response?.data : error?.response || error?.error || error?.message);
      } else {
          return Promise.reject(error.message);
      }
    }
    let isRefreshing = false;
    let failedQueue = [];

    const processQueue = (error, token = null) => {
      failedQueue.forEach(prom => {
        if (error) {
          prom.reject(error);
        } else {
          prom.resolve(token);
        }
      })
      failedQueue = [];
    }

    instance.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
      const originalRequest = error.config;
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({
              resolve,
              reject
            });
          }).then(token => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token;
            return axios(originalRequest);
          }).catch(err => {
            return Promise.reject(err);
          });
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise(function (resolve, reject) {
          AuthService.callRefresh()
            .then((res) => {
              localStorage.setItem('accessToken', res.data.access);
              localStorage.setItem('refreshToken', res.data.refresh);
              originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access;
              processQueue(null, res.data.access);
              resolve(axios(originalRequest));
              return res
            })
            .catch((err) => {
              processQueue(err, null);
              reject(err);
            })
            .finally((data) => {
              isRefreshing = false;
              // if (data?.tokens?.access?.token) store.dispatch({type: "SET_AUTH_DATA", data})
            });
        });
      }
      return Promise.reject(error);
    });
    return instance(options).then(onSuccess).catch(onError);
}

export const simpleRequest = (options) => {
  const header = {
    'Content-Type': 'application/json',
    "Accept": "application/json",
  }
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: header
  })
  const onSuccess = (response) => {
    return response;
  }
  const onError = async (error) => {
    if (error.response) {
      return Promise.reject(!!error.response.data ? error.response.data : error.response || error.message);
    } else if (error.request) {
      return Promise.reject(error.request);
    } else {
      return Promise.reject(error.message);
    }
  }
  return instance(options).then(onSuccess).catch(onError);
};
