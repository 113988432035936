import React from "react";
import { useLocation, Link } from "react-router-dom";
import AssistifyWhiteLogo from "../../assets/images/assistify-white-letter.png"
import { isUserAuthenticated, logout } from "../../utils/authFunctions";
import styled from "styled-components";


const NavMenuItem = styled(Link)`
  line-height: 2.2rem;
  padding: 0rem 1rem 0rem 1rem;
  text-align: center;
`

const NavBar = () => {
  const isLoggedIn = isUserAuthenticated();
  const location = useLocation();

  const handleLogout = async () => {
    await logout();
  }


  const isMyChatBots = location.pathname.startsWith('/my-chatbots') || location.pathname.startsWith('/chatbot');
  const isDemoChatBots = location.pathname.startsWith('/demo');
  return (
    <nav className="isolate bg-common">
      <div className="px-4 pt-5 lg:px-4">
        <nav className="flex items-center justify-between" aria-label="Global">
          <div className="flex lg:flex-1">
            <Link className="-m-1.5 p-1.5" to="/">
              <img style={{ height: 48 }} src={AssistifyWhiteLogo}/>
            </Link>
          </div>
          <div className="flex lg:hidden">
            <button type="button" className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
              <span className="sr-only">Open main menu</span>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true" className="h-6 w-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"></path>
              </svg>
            </button>
          </div>
          <div className="hidden lg:flex lg:gap-x-12">
            {/* {
              isLoggedIn ?
                <>
                  <NavMenuItem target="_self" className={`${isDemoChatBots ? 'common-card' : ''} text-sm font-semibold leading-6 text-gray-900`} to="/demo">Demo</NavMenuItem>
                  <NavMenuItem target="_self" className={`${isMyChatBots ? 'common-card' : ''} text-sm font-semibold leading-6 text-gray-900`} to="/my-chatbots">My Chatbots</NavMenuItem>
                </>
              : null
            } */}
          </div>
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            {
              !isLoggedIn ?
                <NavMenuItem className="common-card text-sm font-semibold leading-6 text-gray-900" to="/signin">Log In</NavMenuItem>
              : <NavMenuItem className="common-card text-sm font-semibold leading-6 text-gray-900" to="javascript:void(0)" onClick={handleLogout}>
                Logout
              </NavMenuItem>
            }
          </div>
        </nav>
      </div>
    </nav>
  )
}

export default NavBar;